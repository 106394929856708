<template>
  <div :class="rootClasses" :style="rootStyle">
    <div class="no-results__content">
      <div class="no-results__icon">
        <nuxt-icon name="logo" filled />
      </div>
      <div class="no-results__text">
        <template v-if="!$slots['default']">
          {{ $t('search.no_results_found') }}
        </template>
        <slot v-else></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'

export default {
  name: 'NoResults',
  props: {
    minHeight: { type: Number, default: null },
    inline: { type: Boolean, default: () => false },
    noBackground: { type: Boolean, default: () => false },
  },
  setup(props) {
    const rootStyle = computed(() => ({
      minHeight: props.minHeight ? props.minHeight + 'px' : undefined,
    }))
    const rootClasses = computed(() => ({
      'no-results': true,
      'no-results--inline': props.inline,
      'no-results--no-background': props.noBackground,
    }))

    return {
      rootStyle,
      rootClasses,
    }
  },
}
</script>

<style lang="scss">
.no-results {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: var(--hb-gray2);
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: center;
  }

  &__text {
    p + * {
      margin-top: 35px;
    }
  }

  &__icon {
    font-size: 42px;
    line-height: 1;
  }

  &--inline {
    .no-results__content {
      flex-direction: row;
    }

    .no-results__icon {
      font-size: 26px;
    }
  }

  &--no-background {
    background: transparent;
  }
}
</style>
